import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useMeta, appColors } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"

import { Layout, Seo } from "components/common"

import {
  AppHero,
  AppBadges,
  AppFeatures,
  AppTestimonials,
  AppPricing,
  AppComparison,
} from "src/sections/app"

const AppTemplate = ({ data: { app } }) => {
  const meta = useMeta(app)

  const {
    slug,
    salesforceAppexchangeUrl,
    hero,
    badges,
    features,
    testimonials,
    pricing,
    comparison,
    disabled,
  } = app

  return (
    <Layout>
      <Seo {...meta} />

      <MobileNav slug={slug} />
      <PrimaryNav slug={slug} />

      <AppStickyNav
        disabled={disabled}
        slug={slug}
        salesforceAppexchangeUrl={salesforceAppexchangeUrl}
      />

      <ThemeWrapper id={slug}>
        <AppHero
          {...hero}
          slug={slug}
          salesforceAppexchangeUrl={salesforceAppexchangeUrl}
          disabled={disabled}
        />

        {badges && <AppBadges {...badges} />}
        {features && <AppFeatures features={features} />}
        {testimonials && <AppTestimonials {...testimonials} />}
        {pricing && (
          <AppPricing
            {...pricing}
            disabled={disabled}
            salesforceAppexchangeUrl={salesforceAppexchangeUrl}
          />
        )}
        {comparison && <AppComparison {...comparison} />}
      </ThemeWrapper>
    </Layout>
  )
}

const ThemeWrapper = styled.div`
  --app-theme-color: ${({ id }) => appColors[id].dark};
  --app-gradient-color: ${({ id }) => appColors[id].textGradient};

  /* temp remove watch-demo button */
  &#payments,
  &#forms {
    .watch-demo {
      display: none;
    }
  }
`

export const query = graphql`
  query AppSlug($slug: String!) {
    app: contentfulApp(slug: { eq: $slug }) {
      ...App
    }
  }
`

export default AppTemplate
